.Plans{
    display: flex;
    flex-direction: column;
    gap: 5rem;
    margin-top: -4rem;
}
.Plans .header-plans {
    display: flex;
    gap: 3rem;
    justify-content: center;
}
.header-plans>span{
    justify-content: center;
    font-size:3rem;
    font-weight:bold;
    font-style: italic;
}
.header-plans>span:nth-child(2){
    color: #fff;
}
.plans{
    align-items: center;
    display: flex;
    gap: 3rem;
    justify-content: center;
}
.plan:nth-child(2){
    background-color: var(--orange);
    transform: scale(1.1);
}
.plan:nth-child(2)>svg{
    fill: white;
}
.plan{
    color: #fff;
    padding: 1.5rem;
    background: var(--caloryCard);
    display: flex;
    flex-direction: column;
    width: 15rem;
}
.plan>svg{
    fill: var(--orange);
    width: 2rem;
    height: 2rem;
}
.plan .plan-name{
    font-weight: bold;
    font-size: 1rem;
    line-height:5rem;
}
.plan .plan-price{
    font-weight: 600;
    font-size: 3rem;
    line-height:5rem;
}
.plan-demo{
    font-size:1rem;
    padding: 2rem 0;
}
.plan-demo>img{
    margin-left: 1rem;
    width: 0.7rem;
}
.feature{
    display: flex;
    flex-direction: column;   
}
.feature span{
    align-items: center;
    gap: 1rem;
    justify-items: center;
    display: flex;
    padding: 10px 0;
}
.feature span img{
    width: 1rem;
}
.button-plan{
    position: relative;
}
.button-plan::before{
    content: attr(data-content);
    position: absolute;
    top: -1.25em;
    right: -1.25em;
    width: 1.3em;
    display: flex;
    justify-content:center;
    align-items:center;
    border-radius:50%;
    background-color:red;
    color:white;
    padding: 10px;
}
.plan-blur{
    width: 22rem;
    height: 30rem;
}
@media screen and (max-width: 768px) {
    .plans{
        flex-direction: column;
    }
    .header-plans{
        flex-direction: column;
    }
    .plans>:nth-child(2){
        transform: none;
    }
}